import Vue      from 'vue'
import App      from './App.vue'
import vuetify  from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAnMLyw9ACVniJRiu7-o4xj3s9g1I_xvHM',
    libraries: 'places',
  }
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
