<template>
  <v-app>
        <v-main>
            <component :is="whichView"></component>
        </v-main>
  </v-app>
</template>

<script>

import homeView                from '@/views/homeView.vue'

export default {

  mounted()  {},
  computed: {
 
          whichView() {
                return homeView;
          } 
  },
  
  components : { homeView },
  methods    : {},
  data       : () => ({}),

};


</script>