<template>

      <v-container>

          <v-snackbar :color  = "snackBar.color"
                      v-model = "snackBar.display"
                      outlined
                      elevation="24"
                      top 
                      center 
        >
        {{ snackBar.text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color = "snackBar.color"
              text
              v-bind="attrs"
              @click ="snackBar.display = false"
            >
              Close
            </v-btn>
          </template>
          </v-snackbar>

       
          <div class="d-flex justify-space-between align-center">
            <v-img
               max-height="200"
               max-width ="200"
              :src="getImageUrl()"
              contain
            >
            </v-img>
            <v-text class="ml-2">Version : {{ getVersion }}</v-text>
           </div>
        
          <v-card-text>
               The map below shows your current location. Please use the map to pin point where your LPG tank is located and then, fill in the form so we can update your account. This helps our drivers and engineers.
          </v-card-text>

          <v-card  class="mx-auto rounded-1" elevation = "1">

            <v-col  cols="12">
                      <v-text-field
                        label = "3 Word Location"
                        outlined
                        type ="text"
                        :value="parseWhat3Words"
                        readonly
                        required
                        :rules="wordRules"
                        hide-details
                        >
                        <template v-slot:append>     
                        
                        <v-btn
                           color ="pink"
                           class="mb-4"
                           elevation="4"
                           fab
                           icon
                           medium
                            @click    = "sendLocation"
                          :loading    = "isSendingActive" 
                          ><v-icon>mdi-email</v-icon>
                        </v-btn> 

                        </template>
                        </v-text-field>
            </v-col>

           <GmapMap
           :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                      disableDefaultUi: true
                    }"

            :center = 'center'
            :zoom   = '20'
            map-type-id ='satellite'
             style   = 'width:100%;  height: 400px;'
            @click  = 'mark'
          >
            <GmapMarker
             :key="index"
             v-for="(m, index) in markers"
             :position="m.position"
             @click="center=m.position"
            />

           </GmapMap>

           <v-card-text>
              <v-form v-model="valid" @submit.prevent ref="form">
                            
                <v-row>
          
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="name"
                        :rules="nameRules"
                        label="Name/Company"
                        required
                        hide-details
                        type="text"
                        clearable
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="postcode"
                        :rules="postcodeRules"
                        label="Postcode"
                        hide-details
                        required
                        type="text"
                        clearable
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="driver"
                        label="Driver"
                        :rules="driverRules"
                        hide-details
                        type="text"
                        clearable
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="account"
                        label="Acc Number : (Optional)"
                        hide-details
                        type="text"
                        clearable
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="email"
                        label="E-mail : (Optional)"
                        hide-details
                        type="email"
                        clearable
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        v-model="phone"
                        label="Telephone : (Optional)"
                        hide-details
                        type="number"
                        clearable
                      ></v-text-field>
                    </v-col>

                  </v-row>
                  
                  <v-card-actions v-if="false" class="mt-3">
                    <v-spacer></v-spacer>
                    <v-btn  
                            :disabled  = "disableSendButton"
                            color      = primary
                            @click     = "this.sendLocation"
                            :loading   = "isSendingActive"    
                    >Send Location
                    </v-btn>
                  </v-card-actions>

              </v-form>
           </v-card-text>
    
          </v-card>

      </v-container> 
 
   
</template>

<script>

import { apiResource } from '@/store/apiResource'
import axios           from "axios";

export default {  

      mounted() {
        this.geoLocate();
      },
  
      methods    : {

        getImageUrl() {
             return '../php/images/logo.png'
        },


        addMarker( marker ) {
         this.markers = [];
         this.markers.push({ position: marker });
        },

        mark(event) {

            let pos = {
                   lat: event.latLng.lat(),
                   lng: event.latLng.lng(),
            };
            
            this.addMarker            ( pos );
            this.getWhat3WordsDirect  ( pos );
    
        },

        geoLocate() {
             let options       = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
             navigator.geolocation.getCurrentPosition( this.positionSuccess, this.positionError, options);
        },

        sendLocation() {
           
                        if ( this.$refs.form.validate() == false) {
                              this.snackBar.color = "error";
                              this.snackBar.text = "Submission form has an error, Please correct and re-submit";
                              this.snackBar.display = true;
                              return;
                        }
                            

                        const   url         =  apiResource.getResourceUrl( "sendLocation" );
                        this.sendingActive  = true;

                        const postData = {
                           what3words     : this.what3words.words,
                           nearestPlace   : this.what3words.nearestPlace,
                           name           : this.name,
                           account        : this.account,
                           postcode       : this.postcode,
                           email          : this.email,
                           driver         : this.driver,
                           phone          : this.phone
                        };

                        axios.post( url, postData ).then( response => {
                                  if ( response.data ) {
                                     this.sendingActive = false
                                      this.snackBar.color = "primary";
                                      this.snackBar.text =  "E-mail submitted successfully.";
                                      this.snackBar.display = true;
                                      this.$refs.form.reset();
                                  }
                        });
        },
      
        positionSuccess ( position ) {

            let pos = {
                   lat: position.coords.latitude,
                   lng: position.coords.longitude,
            };
             
           this.center             = pos;
           this.addMarker          ( pos );
           this.getWhat3WordsDirect( pos );

        },
          
        positionError( error )  {
            console.warn(`Error: ${error.message}`);
            this.gpsPosition = null;
        },
        
        getWhat3WordsDirect( position ) {

            let coordinates       =  `${position.lat},${position.lng}`;
            coordinates           =  encodeURIComponent(coordinates);
            const apiKey          =  "5GK6G2CD"  
            let   url             =  `https://api.what3words.com/v3/convert-to-3wa?coordinates=${coordinates}&key=${apiKey}`;
            axios.get(url ).then( response => {
                              if ( response.data ) {
                                    this.what3words      = response.data;
                              }
            });
   
        },

        getWhat3WordsServer( position) {
      
            const   url      =  apiResource.getResourceUrl( "getLocation" );
            axios.post( url, {
                        latitude   : position.coords.latitude,
                        longitude  : position.coords.longitude
                        }).then( response => {
                                if ( response.data ) {
                                      this.what3words  = response.data;
                                      this.gpsPosition = position;
                                      this.positionActive  = false;
                                }
                        });
   
        },

        resetall(){
          this.what3words  = null;
          this.$refs.form.reset();
        }
 
      },

      computed : {

        getVersion() {
              return apiResource.version;
        },

        isSendingActive() {
            return this.sendingActive;
        },

        isPositionActive() {
            return this.positionActive;
        },

        parseWhat3Words() {
             return ( this.what3words ) ? this.what3words.words : '';
        },

        disableSendButton() {
          return !this.valid;
        }
  
      },

      data: () => ({
  
        center           : { lat: 45.508, lng: -73.587 },
        markers          : [],
        sendingActive    : false,
        positionActive   : false,
        valid            : false,
        position         : null,
        what3words       : null,

        snackBar : {
                      color   : "primary",
                      display : false,
                      text    : "",
        }, 

        postcode          :  '',
        postcodeRules: [
          value => {
              if (value) return true
              return 'Postcode is required.'
          },
        ],
        
        name     :  '',
        nameRules: [
        value => {
          if (value) return true
             return 'Name/Company is required.'
          },
        ],
        
        email     : '',
        emailRules: [
        value => {
          if (value) return true
            return 'E-mail is requred.'
          },
        ],

        phone      : '',
        phoneRules: [
        value => {
          if (value) return true
            return 'Phone is requred.'
          },
        ],
        
        wordRules: [
        value => {
          if (value) return true
              return 'Location Words are requred.'
        },
        ],
       
        account: '',
        accountRules: [
         value => {
          if (value) return true
            return 'Account Number is required.'
          },
        ],

        driver: '',
        driverRules: [
         value => {
          if (value) return true
              return 'Driver is required.'
          },
        ],
   
        
      
    }),


}
</script>


<style scoped>
</style>