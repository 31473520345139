export const apiResource = {
    
    localDev            :  false,
    localUrlbase        :  "http://localhost",
    liveUrlbase         :  "..//php",
    version             :  "1.0.5",
    
    endpoint            :   {  
                             "getLocation"         : "/getLocation.php",
                             "sendLocation"        : "/sendLocation.php",
    },
  
    getResourceUrl: function( resource ) {
        
        if (this.localDev) {
             return (  this.localUrlbase + this.endpoint[ resource] )
        } else {
            return (  this.liveUrlbase + this.endpoint[ resource] )
        }
        
    }
           
}